import moment from 'moment';

// colors
const chartColors = {
  column: {
    seriesDiterima: '#f8d3ff',
    seriesSiapKirim: '#7eefc7',
    seriesDalamPengiriman: '#06774f',
    seriesTerkirim: '#ffe700',
    seriesSelesai: '#00d4bd',
    seriesDibatalkan: '#FFA1A1',
    bg: '#f8d3ff',
  },
};

const generateDate = count => {
  const arr = [];
  for (let i = 0; i < count; i += 1) {
    arr.push(moment().subtract(count - i, 'days').format('DD/MM'));
  }
  return arr;
};

export default {
  columnChart: {
    series: [
      {
        name: 'Diterima',
        data: [90, 120, 55, 100, 80, 125, 175, 70, 88, 180],
      },
      {
        name: 'Siap Kirim',
        data: [85, 100, 30, 40, 95, 90, 30, 110, 62, 20],
      },
      {
        name: 'Dalam Pengiriman',
        data: [10, 3, 5, 8, 3, 5, 3, 6, 2, 2],
      },
      {
        name: 'Terkirim',
        data: [27, 40, 20, 30, 15, 20, 10, 30, 15, 10],
      },
      {
        name: 'Selesai',
        data: [63, 22, 15, 20, 25, 10, 20, 10, 10, 20],
      },
      {
        name: 'Dibatalkan',
        data: [10, 3, 5, 8, 3, 5, 3, 6, 2, 2],
      },
    ],
    chartOptions: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      colors: [chartColors.column.seriesDiterima, chartColors.column.seriesSiapKirim, chartColors.column.seriesDalamPengiriman, chartColors.column.seriesTerkirim, chartColors.column.seriesSelesai, chartColors.column.seriesDibatalkan],
      plotOptions: {
        bar: {
          columnWidth: '20%',
          colors: {
            backgroundBarColors: [
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
              chartColors.column.bg,
            ],
            backgroundBarRadius: 10,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Montserrat',
        horizontalAlign: 'left',
      },
      stroke: {
        show: true,
        colors: ['transparent'],
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        categories: generateDate(10),
      },
      yaxis: {
        // opposite: isRtl,
      },
      fill: {
        opacity: 1,
      },
    },
  },
};
