<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col cols="12">
        <b-alert show variant="danger" class="mb-2" fade :dismissible="false">
          <div class="alert-body">
            <div class="d-flex align-items-center">
              <font-awesome-icon icon="exclamation-triangle" class="mr-1" />
              <span>
                <strong>Perhatian!</strong> Aplikasi ini masih dalam tahap
                pengembangan, beberapa fitur mungkin belum berjalan dengan baik.
              </span>
            </div>
          </div>
        </b-alert>
      </b-col>

      <b-col v-if="permission !== 'granted'" cols="12">
        <b-alert show variant="warning" fade :dismissible="false">
          <div class="alert-body">
            <div class="d-flex align-items-center">
              <font-awesome-icon icon="exclamation-triangle" class="mr-1" />
              <span>
                <strong>Perhatian!</strong> Izinkan notifikasi untuk mendapatkan
                informasi terbaru. Klik
                <b-link @click="requestPermission">disini</b-link>
                untuk izinkan notifikasi. {{ permission }}
              </span>
            </div>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <!-- Stats Card Vertical -->
    <b-row class="match-height">
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          icon="ShoppingBagIcon"
          statistic="6"
          statistic-title="Toko Tersinkronisasi"
          color="info"
        />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          color="warning"
          icon="MessageSquareIcon"
          statistic="358"
          statistic-title="Produk Tersinkronisasi"
        />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          color="danger"
          icon="ShoppingCartIcon"
          statistic="97.8k"
          statistic-title="Pesanan Diselesaikan"
        />
      </b-col>
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="HeartIcon"
          :statistic="formatted(11200000)"
          statistic-title="Pendapatan Bulan Ini"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="8" md="12" sm="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and legend -->
            <b-card-title class="mb-50"> Pesanan Masuk </b-card-title>
            <!--/ title and legend -->

            <!-- datepicker -->
            <div class="d-flex align-items-center">
              <feather-icon icon="CalendarIcon" size="16" />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </div>
            <!-- datepicker -->
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              type="bar"
              height="300"
              :options="apexChatData.columnChart.chartOptions"
              :series="apexChatData.columnChart.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="4" md="12" sm="12">
        <b-card no-body>
          <div>
            <b-table-simple responsive borderless striped>
              <b-thead>
                <b-tr>
                  <b-th>Nama Toko</b-th>
                  <b-th>Total produk</b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="dataProdukToko.length > 0">
                <b-tr v-for="item in dataProdukToko" :key="item.id">
                  <b-td :marketplace="item" :title="item.marketplace">
                    <b-img
                      :src="marketplaceImage(item.marketplace)"
                      class="img-fluid mr-1"
                      width="20"
                      height="20"
                    />
                    {{ item.marketplaceName }}
                  </b-td>
                  <b-td :count="item" :title="item.count" class="text-right">
                    <b class="text-info">{{ item.count }}</b>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else>
                <b-tr>
                  <b-td colspan="2" class="text-center"> Tidak ada data </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div>
            <!-- disclaimer -->
            <p class="font-small-3 m-2">
              <span class="text-danger">*</span>
              menampilkan 5 toko teratas berdasarkan total produk yang
              tersinkronisasi, jika anda belum melihat toko anda, silahkan
              sinkronisasi toko anda di
              <b-link :to="{ name: 'integration' }">halaman integrasi</b-link>
            </p>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BTableSimple,
  BAvatar,
  BThead,
  BTbody,
  BTh,
  BTr,
  BImg,
  BTd,
  BLink,
  BAlert,
  BButton,
} from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import VueApexCharts from 'vue-apexcharts';
import flatPickr from 'vue-flatpickr-component';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import apexChatData from './apexChartData';
// eslint-disable-next-line import/no-cycle
// import { kFormatter } from '@core/utils/filter';

export default defineComponent({
  name: '',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    StatisticCardVertical,
    BCardTitle,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BTableSimple,
    BAvatar,
    BThead,
    BImg,
    BTbody,
    BTh,
    BTr,
    BTd,
    BLink,
    BAlert,
  },
  data() {
    return {
      permission: null,
      apexChatData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      dataProdukToko: [
        {
          id: 1421,
          marketplace: 'shopee',
          marketplaceName: 'Toko A',
          count: 70,
        },
        {
          id: 1233,
          marketplace: 'shopee',
          marketplaceName: 'Toko B',
          count: 55,
        },
        {
          id: 234,
          marketplace: 'tokopedia',
          marketplaceName: 'Toko C',
          count: 80,
        },
        {
          id: 453,
          marketplace: 'lazada',
          marketplaceName: 'Toko D',
          count: 100,
        },
        {
          id: 123,
          marketplace: 'lazada',
          marketplaceName: 'Toko E',
          count: 53,
        },
      ],
    };
  },
  computed: {
    marketplaceImage() {
      return marketplace => {
        const marketplaceImage = {
          shopee: require('@/assets/images/marketplace/shopee.png'),
          tokopedia: require('@/assets/images/marketplace/tokopedia.png'),
          lazada: require('@/assets/images/marketplace/lazada.png'),
          blibli: require('@/assets/images/marketplace/blibli.png'),
        };
        return marketplaceImage[marketplace];
      };
    },
    formatted() {
      return angka => {
        if (angka >= 1e12) {
          return `${(angka / 1e12).toFixed(1)}t`;
        }
        if (angka >= 1e9) {
          return `${(angka / 1e9).toFixed(1)}m`;
        }
        if (angka >= 1e6) {
          return `${(angka / 1e6).toFixed(1)}jt`;
        }
        if (angka >= 1e4) {
          return `${(angka / 1e3).toFixed(0)}rb`;
        }
        return new Intl.NumberFormat('id-ID').format(angka);
      };
    },
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    async checkPermission() {
      const permission = await Notification.requestPermission();
      this.permission = permission;
    },
    // request permission notification
    async requestPermission() {
      this.$swal({
        title: 'Izinkan Notifikasi',
        text: 'Klik izinkan notifikasi pada browser anda dengan menekan tombol izinkan',
        icon: 'info',
      });
    },
  },
  // methods: {
  //   kFormatter,
  // },
});
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
